<script>
import SiteLayout from "@/layouts/SiteLayout.vue";
import StartBlock from "@/views/Index/IndexView/StartBlock.vue";
import ServersBlock from "@/views/Index/IndexView/ServersBlock.vue";
import LauncherBlock from "@/views/Index/IndexView/LauncherBlock.vue";
import InfoBlock from "@/views/Index/IndexView/InfoBlock.vue";

export default {
    name: "IndexView",
    components: {
        InfoBlock,
        LauncherBlock,
        ServersBlock,
        StartBlock,
        SiteLayout,
    },
};
</script>

<template>
    <site-layout>
        <div class="content">
            <start-block />
            <servers-block />
            <info-block />
            <launcher-block />
        </div>
    </site-layout>
</template>

<style scoped></style>

import axios from "@/axios";

const getters = {};

const state = {};

const mutations = {};

export const LOGIN = "login";
export const REGISTER = "register";

const actions = {
    [LOGIN](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/login", payload)
                .then(() => {
                    resolve();
                    // if (process.env.NODE_ENV === "production") {
                    //     window.location.href = "https://xpixel.ru";
                    // } else {
                    //     window.location.href = "http://xmc.local";
                    // }
                    // context.dispatch(GET_USER).then(() => {
                    //   resolve(response.data);
                    // });
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [REGISTER](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/register", payload)
                .then(() => {
                    resolve();
                    // context.dispatch(GET_USER).then(() => {
                    //   resolve(response.data);
                    // });
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};

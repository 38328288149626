<script>
export default {
    name: "InfoBlock",
};
</script>

<template>
    <div class="block">
        <h2 class="text-center display-3 mb-4">О проекте</h2>

        <div class="row align-items-center">
            <div class="col-12 col-md-7">
                <p>
                    В игре доступны новые ресурсы, инструменты, мобы и блоки,
                    которые помогут в создании более мощных и продвинутых
                    устройств, домов и построек. Среди модов можно найти
                    модификации для создания механики, предметов и даже целых
                    новых миров.
                </p>
                <p>
                    На проекте <strong>XPIXEL</strong> есть множество
                    разнообразных возможностей для игроков всех уровней, от
                    начинающих до профессионалов. Создавайте свои уникальные
                    постройки, исследуйте мир, сражайтесь с монстрами и
                    участвуйте в множестве увлекательных приключений.
                </p>
            </div>
            <div class="col-12 col-md-5 d-flex justify-content-center">
                <img
                    src="@/assets/mc4.jpeg"
                    class="rounded-4"
                    height="450"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<style scoped></style>

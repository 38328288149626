<template>
    <site-layout>
        <div class="container mt-4">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-important alert-dark">
                        <div class="mb-2">
                            Спасибо за участие в развитии проекта XPIXEL. Вы
                            можете скачать лаунчер для Windows, Linux или MacOS
                            по кнопкам ниже.
                        </div>
                        <div>
                            <a
                                href="https://kingdom.streamer.fun/xLauncher.exe"
                                download
                                class="btn btn-windows me-2"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="me-2"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    ></path>
                                    <path
                                        d="M17.8 20l-12 -1.5c-1 -.1 -1.8 -.9 -1.8 -1.9v-9.2c0 -1 .8 -1.8 1.8 -1.9l12 -1.5c1.2 -.1 2.2 .8 2.2 1.9v12.1c0 1.2 -1.1 2.1 -2.2 1.9z"
                                    ></path>
                                    <path d="M12 5l0 14"></path>
                                    <path d="M4 12l16 0"></path>
                                </svg>
                                Скачать лаунчер</a
                            >
                            <a
                                href="https://kingdom.streamer.fun/xLauncher.jar"
                                download
                                class="btn btn-light me-2"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="me-2"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    ></path>
                                    <path
                                        d="M9 7c-3 0 -4 3 -4 5.5c0 3 2 7.5 4 7.5c1.088 -.046 1.679 -.5 3 -.5c1.312 0 1.5 .5 3 .5s4 -3 4 -5c-.028 -.01 -2.472 -.403 -2.5 -3c-.019 -2.17 2.416 -2.954 2.5 -3c-1.023 -1.492 -2.951 -1.963 -3.5 -2c-1.433 -.111 -2.83 1 -3.5 1c-.68 0 -1.9 -1 -3 -1z"
                                    ></path>
                                    <path
                                        d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2"
                                    ></path>
                                </svg>
                                Скачать лаунчер</a
                            >
                            <a
                                href="https://kingdom.streamer.fun/xLauncher.jar"
                                download
                                class="btn btn-orange me-2"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="me-2"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    ></path>
                                    <path
                                        d="M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                                    ></path>
                                    <path
                                        d="M17.723 7.41a7.992 7.992 0 0 0 -3.74 -2.162m-3.971 0a7.993 7.993 0 0 0 -3.789 2.216m-1.881 3.215a8 8 0 0 0 -.342 2.32c0 .738 .1 1.453 .287 2.132m1.96 3.428a7.993 7.993 0 0 0 3.759 2.19m4 0a7.993 7.993 0 0 0 3.747 -2.186m1.962 -3.43a8.008 8.008 0 0 0 .287 -2.131c0 -.764 -.107 -1.503 -.307 -2.203"
                                    ></path>
                                    <path
                                        d="M5 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                                    ></path>
                                    <path
                                        d="M19 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                                    ></path>
                                </svg>
                                Скачать лаунчер</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <h2 class="text-white">Внешний вид</h2>
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-center mb-4">
                                <img
                                    v-if="userSkin"
                                    :src="
                                        'data:image/png;base64,' +
                                        userSkin.images.front
                                    "
                                    style="
                                        image-rendering: pixelated;
                                        max-width: 200px;
                                    "
                                    class="w-50 p-2"
                                />
                                <img
                                    v-if="userSkin"
                                    :src="
                                        'data:image/png;base64,' +
                                        userSkin.images.back
                                    "
                                    style="
                                        image-rendering: pixelated;
                                        max-width: 200px;
                                    "
                                    class="w-50 p-2"
                                />
                            </div>
                            <div class="row">
                                <div
                                    class="col-12 col-md-6 d-flex justify-content-md-end"
                                >
                                    <a
                                        href="#"
                                        class="btn btn-lime"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal-skins"
                                    >
                                        Изменить скин
                                    </a>
                                </div>
                                <div class="col-12 col-md-6">
                                    <a
                                        href="#"
                                        class="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal-capes"
                                    >
                                        Изменить плащ
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <h2>Информация</h2>
                    <div class="card">
                        <div class="card-body">
                            <div class="row h3">
                                <div class="col fw-bold">Ваш ник</div>
                                <div class="col-auto fw-normal">
                                    <img
                                        v-if="userSkin"
                                        :src="
                                            'data:image/png;base64,' +
                                            userSkin.head
                                        "
                                        style="image-rendering: pixelated"
                                        width="16"
                                    />
                                    {{ user.name }}
                                </div>
                            </div>
                            <div class="row h3">
                                <div class="col fw-bold">Ваша роль</div>
                                <div class="col-auto fw-normal">
                                    <span
                                        v-for="role in user.roles"
                                        :key="'role_' + role.id"
                                        >{{ role.role }}</span
                                    >
                                </div>
                            </div>
                            <div class="row h3">
                                <div class="col fw-bold">Ваша почта</div>
                                <div class="col-auto fw-normal">
                                    {{ user.email }}
                                </div>
                            </div>
                            <div class="row h3">
                                <div class="col">Дата регистрации</div>
                                <div class="col-auto fw-normal">
                                    {{ user.created_at }}
                                </div>
                            </div>
                        </div>
                        <div class="list-group list-group-flush">
                            <a
                                href="/logout"
                                class="list-group-item list-group-item-action"
                                ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="me-1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="1"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    ></path>
                                    <path
                                        d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
                                    ></path>
                                    <path d="M7 12h14l-3 -3m0 6l3 -3"></path>
                                </svg>
                                Выход</a
                            >
                        </div>
                    </div>
                    <h2 class="mt-3">Сервера</h2>
                    <server-monitor
                        class="p-3 rounded-4 mt-3"
                        name="SteamPunk"
                        ip="s3.streamer.fun"
                        port="29030"
                        :background="require('@/assets/servers/steampunk.png')"
                    />
                    <server-monitor
                        class="p-3 rounded-4 mt-3"
                        name="HiTech"
                        ip="s3.streamer.fun"
                        port="25800"
                        :background="require('@/assets/servers/tech.jpg')"
                    />
                </div>
            </div>
        </div>

        <div
            class="modal modal-blur fade"
            id="modal-skins"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
        >
            <div
                class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Выберите скин или загрузите новый
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div
                                class="col-12 col-sm-4"
                                v-for="skin in user.skins"
                                :key="'skin_' + skin.id"
                            >
                                <label class="form-imagecheck w-100 mb-2">
                                    <input
                                        name="form-skin"
                                        type="radio"
                                        :value="skin.id"
                                        class="form-imagecheck-input"
                                        v-model="skinId"
                                        @change="updateSkinId"
                                        :checked="skin.default"
                                    />
                                    <span class="form-imagecheck-figure">
                                        <img
                                            v-if="skin.images.front"
                                            :src="
                                                'data:image/png;base64,' +
                                                skin.images.front
                                            "
                                            style="image-rendering: pixelated"
                                            class="w-50 p-2"
                                        />
                                        <img
                                            v-if="skin.images.back"
                                            :src="
                                                'data:image/png;base64,' +
                                                skin.images.back
                                            "
                                            style="image-rendering: pixelated"
                                            class="w-50 p-2"
                                        />
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-primary me-auto"
                            @click="$refs.fileSkin.click()"
                            v-if="fileSkin.status === 0"
                        >
                            Загрузить скин
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary me-auto disabled"
                            disabled
                            v-if="fileSkin.status === 1"
                        >
                            Загрузка...
                        </button>
                        <button
                            type="button"
                            class="btn"
                            data-bs-dismiss="modal"
                        >
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-none">
                <input
                    ref="fileSkin"
                    @change="handleFileUploadSkin"
                    type="file"
                />
            </div>
        </div>

        <div
            class="modal modal-blur fade"
            id="modal-capes"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
        >
            <div
                class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Выберите плащ или загрузите новый
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div
                                class="col-12 col-sm-4"
                                v-for="cape in user.capes"
                                :key="'cape_' + cape.id"
                            >
                                <label class="form-imagecheck w-100 mb-2">
                                    <input
                                        name="form-cape"
                                        type="radio"
                                        :value="cape.id"
                                        class="form-imagecheck-input"
                                        v-model="capeId"
                                        @change="updateCapeId"
                                        :checked="cape.default"
                                    />
                                    <span class="form-imagecheck-figure">
                                        <img
                                            v-if="cape.base64"
                                            :src="
                                                'data:image/png;base64,' +
                                                cape.base64
                                            "
                                            style="image-rendering: pixelated"
                                            class="w-100"
                                        />
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="me-auto">
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="$refs.fileCape.click()"
                                v-if="fileCape.status === 0"
                            >
                                Загрузить плащ
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary me-auto disabled"
                                disabled
                                v-if="fileCape.status === 1"
                            >
                                Загрузка...
                            </button>
                            <button
                                type="button"
                                class="btn btn-warning ms-2"
                                @click="removeCape"
                                v-if="fileCape.status === 0 && userCape"
                            >
                                Удалить плащ
                            </button>
                        </div>
                        <button
                            type="button"
                            class="btn"
                            data-bs-dismiss="modal"
                            ref="closeCape"
                        >
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-none">
                <input
                    ref="fileCape"
                    @change="handleFileUploadCape"
                    type="file"
                />
            </div>
        </div>
    </site-layout>
</template>

<script>
// import PanelLayout from "@/layouts/PanelLayoutOld.vue";
import {
    GET_USER,
    UPDATE_CAPE,
    UPDATE_SKIN,
    UPLOAD_CAPE,
    UPLOAD_SKIN,
} from "@/store/modules/user";
import ServerMonitor from "@/views/Panel/PanelView/ServerMonitor";
import SiteLayout from "@/layouts/SiteLayout.vue";
export default {
    name: "PanelView",
    components: { SiteLayout, ServerMonitor },
    data: () => ({
        fileSkin: {
            data: null,
            status: 0,
        },
        fileCape: {
            data: null,
            status: 0,
        },
        skinId: null,
        capeId: null,
    }),
    computed: {
        user() {
            return this.$store.getters.user;
        },
        userSkin() {
            return this.user ? this.user.skins.find((v) => v.default) : false;
        },
        userCape() {
            return this.user ? this.user.capes.find((v) => v.default) : false;
        },
    },
    methods: {
        handleFileUploadSkin() {
            // debugger;
            this.fileSkin.data = this.$refs.fileSkin.files[0];
            if (this.fileSkin.data) {
                this.fileSkin.status = 1;
                let formData = new FormData();
                formData.append("file", this.fileSkin.data);
                this.$store
                    .dispatch(UPLOAD_SKIN, formData)
                    .then(() => {
                        this.fileSkin.status = 0;
                        this.fileSkin.data = null;
                        this.$store.dispatch(GET_USER);
                    })
                    .catch(() => {
                        this.fileSkin.status = 0;
                        this.fileSkin.data = null;
                        this.$store.dispatch(GET_USER);
                    });
            }
        },
        handleFileUploadCape() {
            // debugger;
            this.fileCape.data = this.$refs.fileCape.files[0];
            if (this.fileCape.data) {
                this.fileCape.status = 1;
                let formData = new FormData();
                formData.append("file", this.fileCape.data);
                this.$store
                    .dispatch(UPLOAD_CAPE, formData)
                    .then(() => {
                        this.fileCape.status = 0;
                        this.fileCape.data = null;
                        this.$store.dispatch(GET_USER);
                    })
                    .catch(() => {
                        this.fileCape.status = 0;
                        this.fileCape.data = null;
                        this.$store.dispatch(GET_USER);
                    });
            }
        },
        updateSkinId() {
            this.fileSkin.status = 1;
            this.$store
                .dispatch(UPDATE_SKIN, {
                    skin_id: this.skinId,
                })
                .then(() => {
                    this.$store.dispatch(GET_USER);
                    this.fileSkin.status = 0;
                })
                .catch(() => {
                    this.$store.dispatch(GET_USER);
                    this.fileSkin.status = 0;
                });
        },
        updateCapeId() {
            this.fileCape.status = 1;
            this.$store
                .dispatch(UPDATE_CAPE, {
                    cape_id: this.capeId,
                })
                .then(() => {
                    this.$store.dispatch(GET_USER);
                    this.fileCape.status = 0;
                })
                .catch(() => {
                    this.$store.dispatch(GET_USER);
                    this.fileCape.status = 0;
                });
        },
        removeCape() {
            this.capeId = null;
            this.updateCapeId();
            this.$refs.closeCape.click();
        },
    },
};
</script>

<style scoped></style>

import axios from "@/axios";

const getters = {
    user(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
};

const state = {
    user: false,
    isAuthenticated: false,
};

export const SET_USER = "setUser";
export const SET_AUTHENTICATED = "setAuthenticated";

const mutations = {
    [SET_USER](state, payload) {
        state.user = payload;
    },
    [SET_AUTHENTICATED](state, payload) {
        state.isAuthenticated = payload;
    },
};

export const GET_USER = "getUser";
export const UPLOAD_SKIN = "uploadSkin";
export const UPDATE_SKIN = "updateSkin";
export const UPLOAD_CAPE = "uploadCape";
export const UPDATE_CAPE = "updateCape";

const actions = {
    [GET_USER](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/user", payload)
                .then((response) => {
                    context.commit(SET_USER, response.data);
                    context.commit(SET_AUTHENTICATED, response.data !== false);
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPDATE_SKIN](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/user/updateSkin", payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPLOAD_SKIN](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/user/uploadSkin", payload, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPDATE_CAPE](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/user/updateCape", payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPLOAD_CAPE](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/user/uploadCape", payload, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};

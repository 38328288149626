import { createRouter, createWebHistory } from "vue-router";
import IndexView from "@/views/Index/IndexView";
import PanelView from "@/views/Panel/PanelView";
import store from "@/store";

const routes = [
    {
        path: "/",
        name: "index",
        component: IndexView,
    },
    {
        path: "/home",
        name: "home",
        component: PanelView,
    },
    {
        path: "/login",
        name: "login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "auth" */ "../views/Auth/LoginView"),
    },
    {
        path: "/terms",
        name: "terms",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "terms" */ "../views/Policy"),
    },
    {
        path: "/rules",
        name: "rules",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "rules" */ "../views/Rules"),
    },
    {
        path: "/register",
        name: "register",
        component: () =>
            import(/* webpackChunkName: "auth" */ "../views/Auth/RegisterView"),
    },
    {
        path: "/shop",
        name: "shop",
        component: () => import(/* webpackChunkName: "shop" */ "../views/Shop"),
    },
    {
        path: "/shop/success",
        name: "shopSuccess",
        component: () =>
            import(/* webpackChunkName: "shop" */ "../views/Shop/SuccessView"),
    },
    {
        path: "/shop/fail",
        name: "shopFail",
        component: () =>
            import(/* webpackChunkName: "shop" */ "../views/Shop/FailView"),
    },
];

const router = createRouter({
    history: createWebHistory("/"),
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated;

    if (["login", "register"].includes(to.name) && isAuthenticated)
        next({ name: "home" });

    if (
        ![
            "login",
            "register",
            "index",
            "shop",
            "shopSuccess",
            "shopFail",
            "rules",
            "terms",
        ].includes(to.name) &&
        !isAuthenticated
    )
        next({ name: "login" });
    else next();
});

export default router;

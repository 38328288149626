import axios from "@/axios";

const getters = {
    groups(state) {
        return state.groups;
    },
    items(state) {
        return state.items;
    },
    selectedItem(state) {
        return state.selectedItem;
    },
};

const state = {
    groups: [],
    items: [],
    selectedItem: false,
};

export const SET_GROUPS = "setGroups";
export const SET_ITEMS = "setItems";
export const SET_ITEM = "setItem";

const mutations = {
    [SET_GROUPS](state, payload) {
        state.groups = payload;
    },
    [SET_ITEMS](state, payload) {
        state.items = payload;
    },
    [SET_ITEM](state, payload) {
        state.selectedItem = payload;
    },
};

export const GET_GROUPS = "getGroups";
export const GET_ITEMS = "getItems";
export const START_PAYMENT = "startPayment";

const actions = {
    [GET_GROUPS](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/shop/groups", payload)
                .then((response) => {
                    context.commit(SET_GROUPS, response.data);
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [GET_ITEMS](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/shop/items", payload)
                .then((response) => {
                    context.commit(SET_ITEMS, response.data);
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [START_PAYMENT](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/shop/buy", payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};

<script>
export default {
    name: "AppFooter",
};
</script>

<template>
    <div class="position-relative">
        <div class="container text-white mt-5 pb-3">
            <div class="row">
                <div class="col text-muted">
                    &copy; stark {{ new Date().getFullYear() }}
                </div>
                <div class="col-auto">
                    <a href="https://vk.com/xpixelmc" class="text-white fw-bold"
                        >Вконтакте</a
                    >
                </div>
                <div class="col-auto">
                    <a href="https://t.me/xpixelmc" class="text-white fw-bold"
                        >Telegram</a
                    >
                </div>
                <div class="col-auto">
                    <a
                        href="https://discord.gg/y9jSDYAQkd"
                        class="text-white fw-bold"
                        >Discord</a
                    >
                </div>
            </div>
        </div>

        <svg viewBox="0 0 500 200" class="bg-wave">
            <path
                d="M 0,100 C 150,200 350,0 500,100 L 500,00 L 0,0"
                fill="currentColor"
            ></path>
        </svg>
    </div>
</template>

<style scoped lang="scss">
.bg-wave {
    transform: rotate(180deg);
    position: absolute;
    //width: 100%;
    z-index: -1;
    bottom: -150px;
    left: 0;
    right: 0;
    min-height: 700px;
}
</style>

<template>
    <router-view />
</template>

<script>
// import { GET_USER } from "@/store/modules/user";
//
// export default {
//     created() {
//         this.$store.dispatch(GET_USER);
//     },
// };
</script>

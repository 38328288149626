<template>
    <div
        v-if="info"
        class="mon-background"
        :style="{ background: darken(background) }"
    >
        <div
            class="mon-container h-100 d-flex justify-content-between flex-column"
        >
            <div>
                <div class="row mb-2">
                    <div class="col">
                        <div class="row">
                            <div class="col-auto pe-0">
                                <h2 class="mb-1">{{ name }}</h2>
                            </div>
                            <div class="col-auto align-items-start d-flex">
                                <span class="badge bg-success fs-6"
                                    >ОНЛАЙН</span
                                >
                            </div>
                        </div>
                        <div v-if="info.motd_json.text">
                            {{ info.motd_json.text }}
                        </div>
                    </div>
                    <div
                        class="col-auto fw-bold align-self-end"
                        v-if="info.players.now > 0"
                    >
                        Игроков на сервере: {{ info.players.now }}
                    </div>
                </div>
                <div
                    class="progress"
                    style="height: 20px"
                    v-if="info.players.now > 0"
                >
                    <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        :style="
                            'width: ' +
                            (
                                info.players.now /
                                (info.players.max / 100)
                            ).toFixed(2) +
                            '%;'
                        "
                        :aria-valuenow="
                            (
                                info.players.now /
                                (info.players.max / 100)
                            ).toFixed(2)
                        "
                        aria-valuemin="0"
                        :aria-valuemax="info.players.max"
                    ></div>
                </div>
                <div class="row mt-2">
                    <div
                        class="col-auto d-flex align-items-center my-1 pe-0"
                        v-for="player in info.players.sample"
                        :key="'player_' + player.name"
                    >
                        <div class="badge bg-light text-dark">
                            {{ player.name }}
                        </div>
                    </div>
                </div>
                <div v-if="description" class="mt-2" v-html="description"></div>
            </div>
            <!--            <div class="text-end">
                <a href="#launcher" class="btn btn-instagram">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="me-2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                            d="M7 12l5 5l-1.5 1.5a3.536 3.536 0 1 1 -5 -5l1.5 -1.5z"
                        />
                        <path
                            d="M17 12l-5 -5l1.5 -1.5a3.536 3.536 0 1 1 5 5l-1.5 1.5z"
                        />
                        <path d="M3 21l2.5 -2.5" />
                        <path d="M18.5 5.5l2.5 -2.5" />
                        <path d="M10 11l-2 2" />
                        <path d="M13 14l-2 2" />
                    </svg>
                    Играть
                </a>
            </div>-->
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "ServerMonitor",
    props: {
        name: {
            default: "",
        },
        description: {
            default: "",
        },
        ip: {
            default: "",
        },
        port: {
            default: 25565,
        },
        background: {
            default: "",
        },
    },
    data: () => ({
        url: "https://mcapi.us/server/status?ip=",
        info: false,
    }),
    methods: {
        loadData() {
            axios(this.url + this.ip + "&port=" + this.port, {
                method: "GET",
                mode: "no-cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
                withCredentials: false,
            }).then((response) => {
                this.info = response.data;
                // setTimeout(() => {
                //     this.loadData();
                // }, 10 * 1000);
            });
        },
        darken(src) {
            return (
                "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(" +
                src +
                ")"
            );
        },
    },
    mounted() {
        this.loadData();
    },
};
</script>

<style scoped lang="scss">
.mon-background {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-origin: border-box !important;
}
.mon-container {
    color: #fff;
    min-height: 300px;
}
</style>

<script>
export default {
    name: "AppHeader",
};
</script>

<template>
    <svg viewBox="0 0 500 200" class="bg-wave">
        <path
            d="M 0,100 C 150,200 350,0 500,100 L 500,00 L 0,0"
            fill="url(#bg-wave)"
        ></path>

        <defs>
            <pattern
                id="bg-wave"
                patternUnits="userSpaceOnUse"
                width="500"
                height="200"
            >
                <image
                    href="https://images.hdqwalls.com/download/minecraft-night-in-the-woods-4k-jq-2880x1800.jpg"
                    width="500"
                />
            </pattern>
        </defs>
    </svg>
    <div class="container">
        <div class="d-flex justify-content-center align-items-center pt-4">
            <img src="@/assets/logoFull.png" height="200" alt="" />
        </div>

        <div class="header">
            <div class="header-menu">
                <router-link :to="{ name: 'index' }" class="menu-item">
                    Главная
                </router-link>
                <a
                    href="https://forum.xpixel.ru"
                    target="_blank"
                    class="menu-item"
                >
                    Форум
                </a>
                <router-link :to="{ name: 'shop' }" class="menu-item">
                    Магазин
                </router-link>
                <router-link :to="{ name: 'home' }" class="menu-item">
                    Аккаунт
                </router-link>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.header {
    display: flex;
    justify-content: center;
    .header-menu {
        display: inline-flex;
        justify-content: center;
        flex-direction: row;
        //border: 1px solid rgba(0, 0, 0, 0.1);
        //border-radius: 15px;
        //padding: 5px 20px;

        .menu-item {
            margin: 0 12px;
            font-size: 20px;
            font-weight: 600;
            color: #a4a4a4;

            &:hover {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

.bg-wave {
    position: absolute;
    //width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    min-height: 700px;
}
</style>

<script>
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";

export default {
    name: "SiteLayout",
    components: { AppHeader, AppFooter },
};
</script>

<template>
    <app-header></app-header>
    <div class="container">
        <slot></slot>
    </div>
    <app-footer />
</template>

<style scoped></style>

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { GET_USER } from "@/store/modules/user";
import "./registerServiceWorker";

require("@tabler/core/dist/js/tabler.min");
require("@tabler/core/dist/css/tabler.css");
// require("@tabler/core/dist/css/tabler-flags.min.css");
require("@tabler/core/dist/css/tabler-payments.min.css");
require("@tabler/core/dist/css/tabler-vendors.min.css");
require("./styles/main.scss");

store.dispatch(GET_USER);

createApp(App).use(store).use(router).mount("#app");

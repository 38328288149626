<script>
export default {
    name: "LauncherBlock",
};
</script>

<template>
    <div class="block">
        <h2 class="text-center display-3 mb-4" id="launcher">xLauncher</h2>

        <div class="row align-items-center">
            <div class="col-12 col-md-5 d-flex justify-content-center">
                <img
                    src="@/assets/mc3.jpeg"
                    class="rounded-4"
                    height="450"
                    alt=""
                />
            </div>
            <div class="col-12 col-md-7">
                <div class="display-6 mb-4">
                    Установите наш <b>лаунчер</b> для игры на серверах.
                </div>
                <div class="d-flex">
                    <a
                        href="https://kingdom.streamer.fun/xLauncher.exe"
                        download
                        class="btn btn-windows me-2"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="me-2"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                            ></path>
                            <path
                                d="M17.8 20l-12 -1.5c-1 -.1 -1.8 -.9 -1.8 -1.9v-9.2c0 -1 .8 -1.8 1.8 -1.9l12 -1.5c1.2 -.1 2.2 .8 2.2 1.9v12.1c0 1.2 -1.1 2.1 -2.2 1.9z"
                            ></path>
                            <path d="M12 5l0 14"></path>
                            <path d="M4 12l16 0"></path>
                        </svg>
                        Windows</a
                    >
                    <a
                        href="https://kingdom.streamer.fun/xLauncher.jar"
                        download
                        class="btn btn-dark me-2"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="me-2"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                            ></path>
                            <path
                                d="M9 7c-3 0 -4 3 -4 5.5c0 3 2 7.5 4 7.5c1.088 -.046 1.679 -.5 3 -.5c1.312 0 1.5 .5 3 .5s4 -3 4 -5c-.028 -.01 -2.472 -.403 -2.5 -3c-.019 -2.17 2.416 -2.954 2.5 -3c-1.023 -1.492 -2.951 -1.963 -3.5 -2c-1.433 -.111 -2.83 1 -3.5 1c-.68 0 -1.9 -1 -3 -1z"
                            ></path>
                            <path
                                d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2"
                            ></path>
                        </svg>
                        MacOS</a
                    >
                    <a
                        href="https://kingdom.streamer.fun/xLauncher.jar"
                        download
                        class="btn btn-orange me-2"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="me-2"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                            ></path>
                            <path
                                d="M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                            ></path>
                            <path
                                d="M17.723 7.41a7.992 7.992 0 0 0 -3.74 -2.162m-3.971 0a7.993 7.993 0 0 0 -3.789 2.216m-1.881 3.215a8 8 0 0 0 -.342 2.32c0 .738 .1 1.453 .287 2.132m1.96 3.428a7.993 7.993 0 0 0 3.759 2.19m4 0a7.993 7.993 0 0 0 3.747 -2.186m1.962 -3.43a8.008 8.008 0 0 0 .287 -2.131c0 -.764 -.107 -1.503 -.307 -2.203"
                            ></path>
                            <path
                                d="M5 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                            ></path>
                            <path
                                d="M19 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                            ></path>
                        </svg>
                        Ubuntu</a
                    >
                </div>
                <div class="mt-4">
                    <iframe
                        class="rounded-4"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/p8aaLRGWFyc?si=zWAVfZQO41levroN&amp;controls=0"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

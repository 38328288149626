<script>
import ServerMonitor from "@/views/Panel/PanelView/ServerMonitor.vue";

export default {
    name: "ServersBlock",
    components: { ServerMonitor },
};
</script>

<template>
    <div class="block">
        <h2 class="text-center display-3 mb-4">Сервера</h2>
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <server-monitor
                    class="p-3 rounded-4"
                    name="HiTech"
                    description="Добро пожаловать на HiTech - Вашу дверь в мир высоких технологий Minecraft!<br><br>Уникальный сервер Minecraft, который предлагает игрокам захватывающий мир высоких технологий и инноваций. Наша сборка модов тщательно отобрана, чтобы подарить вам незабываемый игровой опыт, полный технических чудес и футуристических приключений."
                    ip="s3.streamer.fun"
                    port="25000"
                    :background="require('@/assets/servers/tech.jpg')"
                />
            </div>
        </div>
    </div>
</template>

<style scoped></style>
